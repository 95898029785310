import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Charity } from '../../../common/Fundraising/CharityPicker';
import { CheckoutStep } from '../helpers';
import { useEvent } from '../EventProvider';
import { useTracking } from '../../../common/Tracking';
import BaseFundraisingForm from '../../../common/Fundraising/FundraisingForm';
import CheckoutContext from '../CheckoutContext';
import UI from '../../../common/UI';

/**
 * Allows the user to pick a charity.
 */
const FundraisingForm = () => {
  const { t } = useTranslation();
  const { track } = useTracking();
  const { event } = useEvent();

  const {
    form,
    session,
    setCharity,
    rememberCharity,
    validators: { [CheckoutStep.Fundraising]: { errors } },
    touch,
    untouch,
    touched,
  } = useContext(CheckoutContext);

  const handleCharityChange = useCallback((charity: Charity | null) => {
    setCharity(charity);

    if (charity) {
      rememberCharity(charity);
      touch('charity');
      track?.('Checkout:CharitySelected', { id: charity.id });
    } else {
      untouch('charity');
      track?.('Checkout:CharityDeselected');
    }
  }, [setCharity, rememberCharity, touch, track, untouch]);

  return (
    <UI.FadeIn>
      <UI.FormGrid>
        <UI.Legend>
          {t('fundraising_form.fundraising_title')}
          {!event.require_fundraising && (
            <>
              {' '}
              <UI.Small>
                <UI.Label style={{ fontWeight: 500 }}>
                  {t('fundraising_form.optional')}
                </UI.Label>
              </UI.Small>
            </>
          )}
        </UI.Legend>
        <BaseFundraisingForm
          charity={session.charities[form.charity?.id]}
          eventId={event.id}
          onChange={handleCharityChange}
          required={event.require_fundraising}
          charities={event.charities}
          allowOtherCharity={event.allow_other_charity}
          errors={errors}
          touched={touched()}
        />
      </UI.FormGrid>
    </UI.FadeIn>
  );
};

export default FundraisingForm;
