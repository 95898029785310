import { Calendar, Check, ChevronDown, ChevronUp, Clock, Plus, Users } from 'react-feather';
import { Fragment, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import pick from 'lodash/pick';
import styled, { css, useTheme } from 'styled-components';
import times from 'lodash/times';
import uniq from 'lodash/uniq';
import upperFirst from 'lodash/upperFirst';

import { AssignmentType, BusinessRegistrationInput, CreateRegistrationInput } from '__generated__/graphql';
import { Availability, Quantities } from '../useQuantities';
import {
  CheckoutStep, Event, Product, Promotion, Ticket, TicketCategory, TimeSlot, getTicketFees, getTicketsForSale,
} from '../helpers';
import { Theme } from '../../../theme';
import { resizedImageUrl } from '../../../common/helpers';
import { useEvent } from '../EventProvider';
import CheckoutContext from '../CheckoutContext';
import DateRange from '../../../common/Common/DateRange';
import DiscountInfo from '../DiscountInfo';
import PriceRange from '../../../common/Common/PriceRange';
import QuantityLabel from '../../../common/Tickets/QuantityLabel';
import SC from '../../../common/UI/SC';
import SoldOutLabel from '../SoldOutLabel';
import SportIcon from '../../../common/Common/SportIcon';
import UI from '../../../common/UI';
import useLocale from '../../../common/useLocale';
import useMediaDevice from '../../../common/useMediaDevice';
import useTimeSlotFormatter from '../../../common/Tickets/useTimeSlotFormatter';

const TicketSelector = () => {
  const { t } = useTranslation();
  const { event, ticketCategories } = useEvent();

  const {
    setRegistrations,
    setBusinessRegistrations,
    quantities,
    availability,
    validators: { [CheckoutStep.Tickets]: { errors } },
  } = useContext(CheckoutContext);

  // Initially expand a ticket category if is the only one or if one its tickets has been selected.
  const getInitialTicketCategoryIds = useCallback(
    () => ticketCategories
      .filter((ticketCategory) => ticketCategories.length === 1 || quantities.ticketCategories[ticketCategory.id] > 0)
      .map(({ id }) => id),
    [ticketCategories, quantities],
  );

  const [activeTicketCategories, setActiveTicketCategories] = useState(getInitialTicketCategoryIds);

  const ticketCategoriesRef = useRef(ticketCategories);

  /**
   * Reset the active ticket categories when the list of ticket categories changes.
   * This can happen when an invitation code is entered.
   */
  useEffect(() => {
    if (ticketCategories !== ticketCategoriesRef.current) {
      setActiveTicketCategories(getInitialTicketCategoryIds);
      ticketCategoriesRef.current = ticketCategories;
    }
  }, [ticketCategories, getInitialTicketCategoryIds]);

  const toggleTicketCategory = (ticketCategory: TicketCategory) => {
    if (quantities.ticketCategories[ticketCategory.id] === 0) {
      setActiveTicketCategories((ids) => (ids.includes(ticketCategory.id)
        ? ids.filter((activeId) => activeId !== ticketCategory.id)
        : [...ids, ticketCategory.id]));
    }
  };

  const handleBusinessTicketChange = useCallback(
    (quantity: number, ticket: Ticket, promotion: Promotion, timeSlot?: TimeSlot) => {
      setBusinessRegistrations((registrations) => {
        // Find the index of the promotion and time slot combination (if it already exists)
        const index = registrations.findIndex(
          (registration) => registration.promotion.id === promotion.id && registration.time_slot?.id === timeSlot?.id,
        );
        const registration = registrations[index];
        const newRegistrations = [...registrations];

        // Add automatic ticket fees as upgrades
        const upgrades = getTicketFees(ticket, promotion, event.products_for_sale);

        const newRegistration: BusinessRegistrationInput = {
          ...registration,
          promotion: { id: promotion.id },
          time_slot: timeSlot ? { id: timeSlot.id } : null,
          upgrades: registration?.upgrades || upgrades,
          quantity,
        };

        if (index > -1) {
          if (newRegistration.quantity === 0) {
            newRegistrations.splice(index, 1);
          } else {
            newRegistrations[index] = newRegistration;
          }
        } else {
          newRegistrations.push(newRegistration);
        }

        return newRegistrations;
      }, event);
    },
    [setBusinessRegistrations, event],
  );

  const handleChange = useCallback(
    (quantity: number, ticket: Ticket, promotion: Promotion, timeSlot?: TimeSlot) => {
      if (ticket.business) {
        handleBusinessTicketChange(quantity, ticket, promotion, timeSlot);
      } else {
        setRegistrations(
          (registrations) => insertRegistration(
            registrations,
            ticket,
            promotion,
            timeSlot,
            quantity * ticket.units,
            event.products_for_sale,
          ),
          event,
        );
      }
    },
    [event, handleBusinessTicketChange, setRegistrations],
  );

  /**
   * Only returns the quantities that are needed by one TicketSelect. This helps to make sure we don't
   * needlessly rerender all TicketSelects when a ticket is added, which can get quite expensive.
   */
  const getTicketQuantitiesAndAvailability = (ticket: Ticket) => {
    const promotionIds: string[] = [];

    if (ticket.current_discount) {
      // Include promotion IDs of all tickets with the same discount, because the DiscountInfo
      // component requires them (some discounts are applicable to combinations of tickets)
      const discount = ticket.current_discount;

      getTicketsForSale(event).forEach((ticket) => {
        if (ticket.current_discount?.id === discount.id) {
          ticket.promotions_for_sale.forEach((promotion) => {
            promotionIds.push(promotion.id);
          });
        }
      });

      event.products_for_sale.forEach((product) => {
        if (product.current_discount?.id === discount.id) {
          product.promotions_for_sale.forEach((promotion) => {
            promotionIds.push(promotion.id);
          });
        }
      });
    } else {
      // If there is no discount, the TicketSelect only needs the quantities for this specific ticket
      ticket.promotions_for_sale.forEach((promotion) => {
        promotionIds.push(promotion.id);
      });
    }

    return {
      quantities: {
        ticketCategories: {}, // Individual TicketSelects don't need this.
        tickets: {
          [ticket.id]: quantities.tickets[ticket.id],
        },
        promotions: pick(quantities.promotions, promotionIds),
        timeSlots: pick(quantities.timeSlots, promotionIds),
        products: {},
        productVariants: {},
        purchases: pick(quantities.purchases, promotionIds),
      },
      availability: {
        promotions: pick(availability.promotions, promotionIds),
        timeSlots: pick(availability.timeSlots, promotionIds),
        productVariants: {},
      },
    };
  };

  return (
    <UI.FormGrid sc={{ gutter: 0.5 }}>
      {ticketCategories.map((ticketCategory) => (
        <UI.Div key={ticketCategory.id}>
          {ticketCategories.length > 1 && (
            <TicketCategoryButton
              onClick={() => toggleTicketCategory(ticketCategory)}
              sc={{ active: activeTicketCategories.includes(ticketCategory.id) }}
              role="button"
            >
              <UI.Badge sc={{ size: 40, mr: 1.5 }}>
                <UI.Icon sc={{ size: 24 / 14 }}>
                  <SportIcon type={ticketCategory.sport_type} />
                </UI.Icon>
              </UI.Badge>
              <UI.Div style={{ width: '100%' }}>
                <UI.H4 sc={{ strong: true }}>
                  {ticketCategory.title}
                </UI.H4>
                <UI.Div sc={{ small: true, color: 'gray.600' }}>
                  <UI.Delimit>
                    {ticketCategory.start_date && (
                      <DateRange start={ticketCategory.start_date} showDay />
                    )}
                    {...ticketCategory.formatted_distances}
                  </UI.Delimit>
                </UI.Div>
              </UI.Div>
              <UI.Icon sc={{ big: true }}>
                {quantities.ticketCategories[ticketCategory.id] > 0
                  ? <Check strokeWidth={1.5} />
                  : (activeTicketCategories.includes(ticketCategory.id)
                    ? <ChevronUp strokeWidth={1.5} />
                    : <ChevronDown strokeWidth={1.5} />)}
              </UI.Icon>
            </TicketCategoryButton>
          )}
          <UI.AnimateHeight isVisible={activeTicketCategories.includes(ticketCategory.id)}>
            <UI.InputGroup sc={{ invalid: !!errors?.tickets }}>
              <UI.FormGrid sc={{ gutter: 0.5, pt: 2 }}>
                {ticketCategory.tickets_for_sale.map((ticket) => (
                  <TicketSelect
                    event={event}
                    ticket={ticket}
                    {...getTicketQuantitiesAndAvailability(ticket)}
                    onChange={handleChange}
                    key={ticket.id}
                  />
                ))}
              </UI.FormGrid>
              <UI.ErrorMessages attribute={t('selector.tickets')} errors={errors.tickets} sc={{ mt: 2 }} />
            </UI.InputGroup>
          </UI.AnimateHeight>
        </UI.Div>
      ))}
    </UI.FormGrid>
  );
};

interface TicketSelectProps {
  event: Event;
  ticket: Ticket;
  quantities: Quantities;
  availability: Availability;
  onChange: (quantity: number, ticket: Ticket, promotion: Promotion, timeSlot?: TimeSlot) => void;
}

/**
 * Memoized using Lodash' isEqual() method because the quantities and availabilities are not stable.
 * This prevents unnecessary rerenders which is especially useful when there are many tickets.
 */
const TicketSelect = memo(
  ({ event, ticket, quantities, availability, onChange }: TicketSelectProps) => {
    const { t } = useTranslation();

    const quantity = quantities.tickets[ticket.id];
    const selected = quantity > 0;
    const defaultPromotion = ticket.promotions_for_sale[0];

    const [showingQuantitySelect, setShowingQuantitySelect] = useState(selected);

    /**
     * If the form state gets changed from somewhere else, update the UI accordingly.
     */
    useEffect(() => {
      if (selected) {
        setShowingQuantitySelect(true);
      }
    }, [selected]);

    /** Only show prices next to promotions if they have different prices */
    const showPromotionPrices = useMemo(
      () => uniq(map(ticket.promotions_for_sale, 'amount')).length > 1,
      [ticket],
    );

    const options = Math.max(1, ticket.upcoming_time_slots.length) * ticket.promotions_for_sale.length;

    /**
     * Selects exactly 1 of the ticket.
     */
    const select = () => {
      if (!ticket.is_sold_out) {
        setShowingQuantitySelect(true);

        if (options === 1) {
          onChange(1, ticket, defaultPromotion, ticket.upcoming_time_slots[0]);
        }
      }
    };

    const canBeSelected = useMemo(() => {
      if (ticket.current_max_per_order === 0 || ticket.is_sold_out) {
        return false;
      }

      for (let i = 0; i < ticket.promotions_for_sale.length; i++) {
        const promotion = ticket.promotions_for_sale[i];

        for (let j = 0; j < ticket.upcoming_time_slots.length; j++) {
          const timeSlot = ticket.upcoming_time_slots[j];

          if (availability.timeSlots[promotion.id][timeSlot.id] > 0) {
            return true;
          }
        }

        if (ticket.upcoming_time_slots.length === 0) {
          if (availability.promotions[promotion.id] > 0) {
            return true;
          }
        }
      }

      return false;
    }, [ticket, availability]);

    const handleDeselect = useCallback(() => setShowingQuantitySelect(false), [setShowingQuantitySelect]);

    const theme = useTheme() as Theme;
    const device = useMediaDevice();

    return (
      <UI.Card
        sc={{ outline: selected ? 'secondary' : 'gray.200', mb: 0 }}
        style={{
          borderWidth: selected ? 2 : undefined,
          padding: selected ? theme.gutter * (device.width <= theme.breakpoints.md ? 0.75 : 1) - 1 : undefined,
        }}
      >
        <UI.GridContainer sc={{ gutter: 0.75 }}>
          {ticket.image && (
            <UI.Div>
              <UI.Image sc={{ ratio: 2 }}>
                <UI.Div>
                  <img
                    src={resizedImageUrl(ticket.image.url, 800)}
                    alt={ticket.title}
                  />
                </UI.Div>
              </UI.Image>
            </UI.Div>
          )}
          <UI.GridContainer sc={{ gutter: 0.5 }} style={{ opacity: ticket.is_sold_out ? 0.5 : 1 }}>
            <UI.GridContainer sc={{ columns: '1fr fit-content(200px)', gutter: 0.5 }}>
              <UI.Div style={{ minWidth: 0 }}>
                <UI.H4>
                  {ticket.title}
                  {' '}
                  {ticket.promotions_for_sale.length === 1 && !defaultPromotion.standard && (
                    <>
                      <UI.Span sc={{ muted: true }}> • </UI.Span>
                      {defaultPromotion.title}
                    </>
                  )}
                </UI.H4>
              </UI.Div>
              <UI.Div sc={{ pt: 0.2, textAlign: 'right' }} style={{ fontWeight: 500 }}>
                <PriceRange
                  prices={ticket.current_price_range.map((price) => ({ amount: price }))}
                />
              </UI.Div>
            </UI.GridContainer>
            {(ticket.business || ticket.units > 1 || ticket.charity?.logo_url) && (
              <UI.FlexContainer sc={{ alignItems: 'center', spaceX: 3 }}>
                {(ticket.business || ticket.units > 1) && (
                  <QuantityLabel>
                    <UI.Delimit>
                      {ticket.units > 1 && (
                        <>
                          <UI.Icon>
                            <Users />
                          </UI.Icon>
                          {' '}
                          {ticket.units}
                        </>
                      )}
                      {ticket.business && t('selector.business')}
                    </UI.Delimit>
                  </QuantityLabel>
                )}
                {ticket.charity?.logo_url && (
                  <UI.Div style={{ height: 23, maxWidth: 60 }}>
                    <img
                      src={ticket.charity.logo_url}
                      alt={ticket.charity.title}
                      style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                    />
                  </UI.Div>
                )}
              </UI.FlexContainer>
            )}
            {ticket.description && (
              <UI.Div sc={{ color: 'gray.600' }}>
                <UI.HTML html={ticket.description} />
              </UI.Div>
            )}
          </UI.GridContainer>
          {!ticket.is_sold_out ? (
            <UI.Div style={{ position: 'relative', minHeight: 40 }}>
              {!showingQuantitySelect && (
                <UI.GridContainer
                  sc={{ columns: options > 1 ? '5fr 2fr' : '1fr', alignVertical: 'center' }}
                  style={{ position: 'absolute', width: '100%', zIndex: 1 }}
                >
                  <UI.Div>
                    <UI.Button
                      onClick={() => select()}
                      sc={{ brand: 'secondary' }}
                      disabled={!canBeSelected}
                      aria-label={options === 1
                        ? t('selector.add_item', { title: ticket.title })
                        : t('selector.choose_item', { title: ticket.title })}
                    >
                      {options === 1 && (
                        <>
                          <UI.Icon>
                            <Plus />
                          </UI.Icon>
                          {' '}
                          {t('selector.add')}
                        </>
                      )}
                      {options > 1 && (
                        <>
                          {t('selector.choose')}
                          {' '}
                          <UI.Icon>
                            <ChevronDown />
                          </UI.Icon>
                        </>
                      )}
                    </UI.Button>
                  </UI.Div>
                  {options > 1 && (
                    <UI.Div sc={{ textAlign: 'right', muted: true, noWrap: true }}>
                      {t('selector.n_options', { count: options })}
                    </UI.Div>
                  )}
                </UI.GridContainer>
              )}

              <UI.AnimateHeight
                duration={options === 1 ? 0 : undefined}
                isVisible={showingQuantitySelect}
              >
                <UI.GridContainer sc={{ gutter: 0.75 }}>
                  {ticket.promotions_for_sale.map((promotion) => (
                    <PromotionSelect
                      ticket={ticket}
                      promotion={promotion}
                      promotionQuantity={quantities.promotions[promotion.id]}
                      promotionAvailability={availability.promotions[promotion.id]}
                      timeSlotQuantities={quantities.timeSlots[promotion.id]}
                      timeSlotAvailabilities={availability.timeSlots[promotion.id]}
                      onChange={onChange}
                      onDeselect={handleDeselect}
                      showPrice={showPromotionPrices}
                      key={promotion.id}
                    />
                  ))}
                  {ticket.current_discount && (
                    <DiscountInfo event={event} discount={ticket.current_discount} quantities={quantities} />
                  )}
                  {options > 1 && (
                    <UI.Div>
                      <UI.HR sc={{ mt: 0, mb: [2.25, 3] }} />
                      <UI.A
                        onClick={() => setShowingQuantitySelect(false)}
                        sc={{ disabled: selected }}
                      >
                        <UI.Icon>
                          <ChevronUp />
                        </UI.Icon>
                        {' '}
                        {t('show_less')}
                      </UI.A>
                    </UI.Div>
                  )}
                </UI.GridContainer>
              </UI.AnimateHeight>
            </UI.Div>
          ) : (
            <UI.Div>
              <SoldOutLabel title={ticket.title} />
            </UI.Div>
          )}
        </UI.GridContainer>
      </UI.Card>
    );
  },
  (prevProps, newProps) => isEqual(prevProps, newProps),
);

TicketSelect.displayName = 'TicketSelect';

interface PromotionSelectProps {
  ticket: Ticket;
  promotion: Promotion;
  promotionQuantity: number;
  promotionAvailability: number;
  timeSlotQuantities: { [timeSlotId: string]: number; };
  timeSlotAvailabilities: { [timeSlotId: string]: number; };
  onChange: (quantity: number, ticket: Ticket, promotion: Promotion, timeSlot?: TimeSlot) => void;
  onDeselect: () => void;
  showPrice?: boolean;
}

/**
 * Memoized using Lodash' isEqual() method because timeSlotQuantities and timeSlotAvailabilities are not stable.
 * This prevents unnecessary rerenders which is especially useful when there are many promotions or time slots.
 */
const PromotionSelect = memo(
  ({
    ticket, promotion, promotionQuantity, promotionAvailability, timeSlotQuantities, timeSlotAvailabilities,
    onChange, onDeselect, showPrice = true,
  }: PromotionSelectProps) => {
    const { t } = useTranslation();
    const { formatCurrency } = useLocale();

    const [focused, setFocused] = useState(false);

    const handleChange = useCallback(
      (quantity: number) => {
        onChange(quantity, ticket, promotion);

        if (ticket.promotions_for_sale.length === 1 && ticket.upcoming_time_slots.length <= 1
          && quantity === 0 && !focused) {
          onDeselect();
        }
      },
      [onChange, ticket, promotion, focused, onDeselect],
    );

    const groupedTimeSlots = groupBy(ticket.upcoming_time_slots, 'start_date');

    const isMultiDate = Object.keys(groupedTimeSlots).length > 1;

    const getTimeSlotQuantitiesAndAvailability = (timeSlots: TimeSlot[]) => {
      const timeSlotIds = timeSlots.map(({ id }) => id);

      return {
        timeSlotQuantities: pick(timeSlotQuantities, timeSlotIds),
        timeSlotAvailabilities: pick(timeSlotAvailabilities, timeSlotIds),
      };
    };

    const handleFocus = useCallback(() => {
      setFocused(true);
    }, [setFocused]);

    const handleBlur = useCallback(() => {
      setFocused(false);
    }, [setFocused]);

    return (
      <UI.Div>
        {ticket.promotions_for_sale.length > 1 && (
          <UI.HR sc={{ mt: 0, mb: [2.25, 3] }} />
        )}

        <UI.GridContainer sc={{ gutter: 0.75 }}>
          {(ticket.upcoming_time_slots.length === 0 || ticket.promotions_for_sale.length > 1) && (
            <UI.GridContainer
              sc={{
                columns: ticket.promotions_for_sale.length > 1
                  && (promotion.is_sold_out || ticket.upcoming_time_slots.length) === 0
                  ? '1fr 143px' : '1fr',
                alignVertical: 'center',
              }}
            >
              {ticket.promotions_for_sale.length > 1 && (
                <UI.GridContainer
                  sc={{
                    columns: ticket.upcoming_time_slots.length > 0 ? '1fr 1fr' : '1fr',
                    gutter: 0,
                  }}
                >
                  <UI.Div>
                    {ticket.upcoming_time_slots.length === 0 && (
                      <UI.InputLabel>{promotion.title || t('selector.standard')}</UI.InputLabel>
                    )}
                    {ticket.upcoming_time_slots.length > 0 && (
                      <UI.Legend>{promotion.title || t('selector.standard')}</UI.Legend>
                    )}
                  </UI.Div>
                  {showPrice && (
                    <UI.Div sc={{ textAlign: ticket.upcoming_time_slots.length > 0 ? 'right' : undefined }}>
                      {promotion.amount === 0 && t('selector.free')}
                      {promotion.amount > 0 && formatCurrency(promotion.amount)}
                    </UI.Div>
                  )}
                </UI.GridContainer>
              )}
              {promotion.is_sold_out && (
                <UI.Div>
                  <SoldOutLabel title={`${ticket.title} / ${promotion.title || t('selector.standard')}`} />
                </UI.Div>
              )}
              {!promotion.is_sold_out && ticket.upcoming_time_slots.length === 0 && (
                <UI.QuantitySelect
                  quantity={promotionQuantity}
                  onChange={handleChange}
                  maxQuantity={promotionQuantity + promotionAvailability}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  title={`${ticket.title} / ${promotion.title || t('selector.standard')}`}
                />
              )}
            </UI.GridContainer>
          )}

          {!promotion.is_sold_out && Object.values(groupedTimeSlots).map((timeSlots) => (
            <UI.GridContainer sc={{ gutter: 0.75 }} key={timeSlots[0].id}>
              {ticket.promotions_for_sale.length === 1 && (
                <UI.HR sc={{ mt: 0, mb: [2.25, 3] }} />
              )}

              <TimeSlotList
                ticket={ticket}
                promotion={promotion}
                timeSlots={timeSlots}
                isMultiDate={isMultiDate}
                {...getTimeSlotQuantitiesAndAvailability(timeSlots)}
                onChange={onChange}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
              />
            </UI.GridContainer>
          ))}
        </UI.GridContainer>
      </UI.Div>
    );
  },
  (prevProps, newProps) => isEqual(prevProps, newProps),
);

PromotionSelect.displayName = 'PromotionSelect';

interface TimeSlotListProps {
  ticket: Ticket;
  promotion: Promotion;
  timeSlots: TimeSlot[];
  isMultiDate: boolean;
  timeSlotQuantities: { [timeSlotId: string]: number; };
  timeSlotAvailabilities: { [timeSlotId: string]: number; };
  onChange: (quantity: number, ticket: Ticket, promotion: Promotion, timeSlot?: TimeSlot) => void;
  handleFocus: () => void;
  handleBlur: () => void;
}

/**
 * Memoized using Lodash' isEqual() method because the quantities and availabilities are not stable.
 * This prevents unnecessary rerenders which is especially useful when there are many time slots.
 */
const TimeSlotList = memo(
  ({
    ticket, promotion, timeSlots, isMultiDate, timeSlotQuantities, timeSlotAvailabilities, onChange, handleFocus,
    handleBlur,
  }: TimeSlotListProps) => {
    const { t } = useTranslation();
    const { formatDate, parseDate } = useLocale();
    const formatTimeSlot = useTimeSlotFormatter();

    const timeSlotGroupIsSelected = (timeSlots: TimeSlot[]) => (
      timeSlots.filter((timeSlot) => timeSlotQuantities[timeSlot.id] > 0).length > 0
    );

    const isGrouped = isMultiDate && timeSlots.length > 1;
    const [showingTimeSlots, setShowingTimeSlots] = useState(!isGrouped || timeSlotGroupIsSelected(timeSlots));

    const toggleTimeSlots = () => setShowingTimeSlots((showing) => !showing);

    const formatDayOfWeek = (timeSlot: TimeSlot) => upperFirst(formatDate(
      parseDate(timeSlot.start_date, { format: 'internal_date' }),
      { format: 'display_day_of_week' },
    ));

    return (
      <>
        {isGrouped && (
          <UI.GridContainer sc={{ columns: '1fr fit-content(120px)', gutter: 0.5 }}>
            <UI.GridContainer sc={{ columns: '12px 1fr', gutter: 0.5 }}>
              <UI.Div>
                <UI.Icon sc={{ muted: true }}>
                  <Calendar />
                </UI.Icon>
              </UI.Div>
              <UI.Div>
                <UI.InputLabel>
                  {formatDayOfWeek(timeSlots[0])}
                </UI.InputLabel>
                {formatDate(
                  parseDate(timeSlots[0].start_date, { format: 'internal_date' }),
                  { format: 'display_date' },
                )}
              </UI.Div>
            </UI.GridContainer>
            <UI.Div>
              <UI.Button
                onClick={() => toggleTimeSlots()}
                sc={{ brand: !showingTimeSlots ? 'secondary' : undefined }}
                disabled={timeSlotGroupIsSelected(timeSlots)}
                aria-label={t('selector.choose_item', {
                  title: `${ticket.title} / ${promotion.title || t('selector.standard')} / ${formatTimeSlot({ ...timeSlots[0], start_time: null })}`,
                })}
              >
                {t('selector.choose')}
                {' '}
                <UI.Icon>
                  {!showingTimeSlots && <ChevronDown />}
                  {showingTimeSlots && <ChevronUp />}
                </UI.Icon>
              </UI.Button>
            </UI.Div>
          </UI.GridContainer>
        )}
        <UI.AnimateHeight isVisible={showingTimeSlots}>
          <UI.GridContainer sc={{ gutter: 0.75 }}>
            {timeSlots.map((timeSlot) => (
              <TimeSlotSelect
                ticket={ticket}
                promotion={promotion}
                timeSlot={timeSlot}
                quantity={timeSlotQuantities[timeSlot.id]}
                showDate={isMultiDate && !isGrouped}
                onChange={onChange}
                maxQuantity={timeSlotQuantities[timeSlot.id] + timeSlotAvailabilities[timeSlot.id]}
                availability={timeSlotAvailabilities[timeSlot.id]}
                onFocus={handleFocus}
                onBlur={handleBlur}
                key={timeSlot.id}
              />
            ))}
          </UI.GridContainer>
        </UI.AnimateHeight>
      </>
    );
  },
  (prevProps, newProps) => isEqual(prevProps, newProps),
);

TimeSlotList.displayName = 'TimeSlotList';

interface TimeSlotSelectProps {
  ticket: Ticket;
  promotion: Promotion;
  timeSlot: TimeSlot;
  quantity: number;
  showDate?: boolean;
  maxQuantity?: number;
  availability: number;
  onChange: (quantity: number, ticket: Ticket, promotion: Promotion, timeSlot?: TimeSlot) => void;
  onFocus: () => void;
  onBlur: () => void;
}

/**
 * Memoized because there are potentially many time slots, especially if there are also multiple promotions.
 */
const TimeSlotSelect = memo(({
  ticket, promotion, timeSlot, quantity, showDate = false, maxQuantity, availability, onChange, onFocus, onBlur,
}: TimeSlotSelectProps) => {
  const { t } = useTranslation();
  const formatTimeSlot = useTimeSlotFormatter();

  const handleChange = useCallback(
    (quantity: number) => {
      onChange(quantity, ticket, promotion, timeSlot);
    },
    [onChange, ticket, promotion, timeSlot],
  );

  return (
    <UI.GridContainer sc={{ columns: '1fr 143px', alignVertical: 'center', gutter: 0.5 }}>
      <UI.GridContainer sc={{ columns: '12px 1fr', gutter: 0.5 }}>
        <UI.Div>
          <UI.Icon sc={{ muted: true }}><Clock /></UI.Icon>
        </UI.Div>
        <UI.Div sc={{ muted: availability === 0 }}>
          <UI.InputLabel>
            {formatTimeSlot({ ...timeSlot, multi_date: showDate })}
          </UI.InputLabel>
          {timeSlot.title}
        </UI.Div>
      </UI.GridContainer>

      <UI.FlexContainer sc={{ justifyContent: 'flex-end' }}>
        {!timeSlot.is_sold_out && (
          <UI.QuantitySelect
            quantity={quantity}
            onChange={handleChange}
            maxQuantity={maxQuantity}
            onFocus={onFocus}
            onBlur={onBlur}
            title={`${ticket.title} / ${promotion.title || t('selector.standard')} / ${formatTimeSlot(timeSlot)}`}
          />
        )}
        {timeSlot.is_sold_out && (
          <SoldOutLabel title={`${ticket.title} / ${promotion.title || t('selector.standard')} / ${formatTimeSlot(timeSlot)}`} />
        )}
      </UI.FlexContainer>
    </UI.GridContainer>
  );
});

TimeSlotSelect.displayName = 'TimeSlotSelect';

/**
 * Inserts a given ticket into the list of registrations and sorts all registrations.
 */
const insertRegistration = (
  registrations: CreateRegistrationInput[],
  ticket: Ticket,
  promotion: Promotion,
  timeSlot: TimeSlot | undefined,
  quantity: number,
  products: Product[],
) => {
  const sameRegistrations: CreateRegistrationInput[] = [];
  const otherRegistrations: CreateRegistrationInput[] = [];

  registrations.forEach((registration) => {
    if (registration.purchase.promotion.id === promotion.id
      && (!timeSlot || registration.time_slot?.id === timeSlot.id)
    ) {
      if (sameRegistrations.length < quantity) {
        sameRegistrations.push(registration);
      }
    } else {
      otherRegistrations.push(registration);
    }
  });

  const missingQuantity = quantity - sameRegistrations.length;

  // Add automatic ticket fees as upgrades
  const upgrades = getTicketFees(ticket, promotion, products);

  const result = [
    ...otherRegistrations,
    ...sameRegistrations,
    ...times(missingQuantity, () => ({
      ticket: { id: ticket.id },
      ...(timeSlot ? {
        time_slot: { id: timeSlot.id },
      } : {}),
      purchase: {
        promotion: { id: promotion.id },
      },
      participant: ticket.assignment_type !== AssignmentType.afterward ? {} : null,
      details: ticket.assignment_type !== AssignmentType.afterward ? {} : null,
      fields: ticket.assignment_type !== AssignmentType.afterward ? [] : null,
      upgrades,
    })),
  ];

  return result;
};

const TicketCategoryButton = styled(UI.A)<SC<{ active: boolean; }>>`
  ${({ sc: { active }, theme }) => css`
    display: flex;
    align-items: center;
    border-radius: ${theme.borderRadiuses.xl}px;
    background: ${theme.colors.gray[75]};
    color: ${theme.colors.gray[500]};
    padding: ${theme.gutter / 2}px;
    transition: all 0.15s ease-in-out;

    ${UI.Badge} {
      background: white;
      color: ${theme.colors.secondary[500]};
      box-shadow: ${theme.shadows.sm};
      border-radius: ${theme.borderRadiuses.lg}px;
    }

    &:hover {
      background: ${theme.colors.gray[100]};
      color: ${theme.colors.gray[800]};
    }

    ${active && css`
      background: ${theme.colors.gray[150]} !important;
      color: ${theme.colors.gray[800]};
    `}
  `}
`;

export default TicketSelector;
