import { ChevronRight, Clock, Plus } from 'react-feather';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import flattenDeep from 'lodash/flattenDeep';

import { GetRegistrationsForSaleQuery as GetRegistrationsForSale } from '__generated__/graphql';
import { Theme } from '../../theme';
import { countItems, resizedImageUrl } from '../../common/helpers';
import UI from '../../common/UI';
import useLocale from '../../common/useLocale';
import useMediaDevice from '../../common/useMediaDevice';
import useTimeSlotFormatter from '../../common/Tickets/useTimeSlotFormatter';

type Registration = GetRegistrationsForSale['event']['registrations_for_sale'][0];

interface TicketCardProps {
  registration: Registration & {
    ticket: Registration['ticket'] & {
      image?: {
        url: string;
      } | null;
      description?: string | null;
    };
  };
  event: { id: string; };
  selected?: boolean;
}

const TicketCard = ({
  registration,
  event,
  selected,
}: TicketCardProps) => {
  const { t } = useTranslation();
  const { formatCurrency, formatDate, parseDate } = useLocale();
  const { search } = useLocation();
  const formatTimeSlot = useTimeSlotFormatter();

  const upgrades = useMemo(() => flattenDeep([
    countItems(registration.resale.upgrades.filter(
      (upgrade) => !upgrade.product.is_ticket_fee,
    ).map(
      (upgrade) => upgrade.product.title,
    ) || []),
  ].filter((value) => value)), [registration]);

  const theme = useTheme() as Theme;
  const device = useMediaDevice();

  return (
    <UI.Card
      sc={{ outline: selected ? 'secondary' : 'gray.200' }}
      style={{
        borderWidth: selected ? 2 : 1,
        padding: selected ? theme.gutter * (device.width <= theme.breakpoints.md ? 0.75 : 1) - 1 : undefined,
      }}
    >
      <UI.GridContainer sc={{ gutter: 0.5 }}>
        {registration.ticket.image && (
          <UI.Div>
            <UI.Image sc={{ ratio: 2 }}>
              <UI.Div>
                <img
                  src={resizedImageUrl(registration.ticket.image.url, 800)}
                  alt={registration.ticket.title}
                />
              </UI.Div>
            </UI.Image>
          </UI.Div>
        )}

        <UI.GridContainer sc={{ columns: '1fr fit-content(200px)', gutter: 0.5 }}>
          <UI.Div style={{ minWidth: 0 }}>
            <UI.H4>
              <UI.Delimit>
                {registration.ticket.title}
                {registration.promotion.title}
              </UI.Delimit>
            </UI.H4>
          </UI.Div>
          <UI.Div sc={{ textAlign: 'right' }} style={{ fontWeight: 500 }}>
            {formatCurrency(registration.resale.total_amount + registration.resale.fee)}
          </UI.Div>
        </UI.GridContainer>

        {(registration.time_slot || registration.start_time || registration.corral_name || upgrades.length > 0) && (
          <UI.GridContainer sc={{ color: 'gray.600', gutter: 0.25 }}>
            {(registration.start_time || registration.corral_name) && (
              <UI.GridContainer sc={{ columns: '16px 1fr', gutter: 0.5 }}>
                <UI.Div>
                  <UI.Icon>
                    <UI.Icons.StartFinish />
                  </UI.Icon>
                </UI.Div>
                <UI.Div>
                  {t('resale.start_label')}
                  {' '}
                  <UI.Delimit>
                    {registration.corral_name}
                    {registration.start_time && formatDate(
                      parseDate(registration.start_time, { format: 'internal_time' }),
                      { format: 'display_time' },
                    )}
                  </UI.Delimit>
                </UI.Div>
              </UI.GridContainer>
            )}

            {registration.time_slot && (
              <UI.GridContainer sc={{ columns: '16px 1fr', gutter: 0.5 }}>
                <UI.Div>
                  <UI.Icon>
                    <Clock />
                  </UI.Icon>
                </UI.Div>
                <UI.Div>
                  {t('resale.time_slot_label')}
                  {' '}
                  <UI.Delimit>
                    {formatTimeSlot(registration.time_slot)}
                    {registration.time_slot.title}
                  </UI.Delimit>
                </UI.Div>
              </UI.GridContainer>
            )}

            {upgrades.length > 0 && (
              <UI.GridContainer sc={{ columns: '16px 1fr', gutter: 0.5 }}>
                <UI.Div>
                  <UI.Icon>
                    <Plus />
                  </UI.Icon>
                </UI.Div>
                <UI.Div>
                  {t('resale.upgrades_label', { count: upgrades.length })}
                  {' '}
                  {upgrades.join(', ')}
                </UI.Div>
              </UI.GridContainer>
            )}
          </UI.GridContainer>
        )}

        {registration.ticket.description && (
          <UI.Info>
            <UI.HTML html={registration.ticket.description} />
          </UI.Info>
        )}

        {!selected && (
          <UI.Div sc={{ pt: 0.5 }}>
            {registration.resale.available && (
              <UI.LinkButton
                to={`/${event.id}/resale/${registration.id}/${registration.resale.public_token}${search}`}
                sc={{ brand: 'secondary', disabled: !registration.resale.available }}
              >
                {t('resale.buy_ticket')}
                {' '}
                <UI.Icon>
                  <ChevronRight />
                </UI.Icon>
              </UI.LinkButton>
            )}
            {!registration.resale.available && (
              <UI.Button disabled>
                {t('resale.reserved')}
              </UI.Button>
            )}
          </UI.Div>
        )}
      </UI.GridContainer>
    </UI.Card>
  );
};

export default TicketCard;
