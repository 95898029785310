import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import map from 'lodash/map';
import omit from 'lodash/omit';
import set from 'lodash/set';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

import {
  BusinessRegistrationInput,
  CompleteCheckoutInput,
  CreateRegistrationInput,
  CreateUpgradeInput,
  InvoiceDetailsInput,
  ParticipantAttributes,
  ParticipantFieldEntryInput,
  ParticipantFieldType,
  ParticipantInput,
  PaymentMethodInput,
  TeamInput,
} from '__generated__/graphql';

import { AddressValue } from '../../common/ParticipantFields/Types/AddressInput';
import { Charity } from '../../common/Fundraising/CharityPicker';
import {
  CheckoutStep, Event, Product, Session, SuccessStep, Ticket, TicketCategory, getKeyedProductVariants,
  getKeyedSellables, getKeyedTimeSlots, getProductsForTicket, getRequiredParticipantAttributes, getStandAloneProducts,
  getTeamFlags, getTicketsForSale,
} from './helpers';
import { Update } from '../../common/helpers';
import { calculateAvailability, calculateQuantities } from './useQuantities';
import { emptyInvoiceDetails } from '../../common/Invoice/InvoiceDetailsForm';
import { getOrderFields, getPromotionFields, prefillFieldEntries } from '../../common/ParticipantFields/helpers';
import SessionStorage from '../../common/SessionStorage';
import StateContext from './StateContext';
import useCheckoutUrlState from './useCheckoutUrlState';
import useDebouncedEffect from '../../common/useDebouncedEffect';

interface PersistedState {
  form?: CompleteCheckoutInput;
  session?: Session;
}

interface StateProviderProps {
  eventId: string;
  step?: CheckoutStep | typeof SuccessStep;
  form?: Partial<CompleteCheckoutInput>;
  session?: Partial<Session>;
  children: ReactNode;
}

/**
 * Retrieves the full state (which contains the form and session object) from sessionStorage
 * and stores it there whenever they get updated.
 */
const StateProvider = ({
  eventId, step, form: extendedForm, session: extendedSession, children,
}: StateProviderProps) => {
  const persistedState: PersistedState = { ...JSON.parse(SessionStorage.getItem(eventId)) };

  const [{
    coupon, invitationCode, charityId, ticketCategoryId,
  }, setUrlState, { parseUrlState }] = useCheckoutUrlState();

  /**
   * Returns the ticket category that is present in the URL.
   */
  const getTicketCategory = useCallback(
    (event: Event) => (
      ticketCategoryId ? event.ticket_categories.filter(({ id }) => id === ticketCategoryId)[0] : null
    ),
    [ticketCategoryId],
  );

  const usePersistedState = step !== SuccessStep;

  const initialForm: CompleteCheckoutInput = {
    event: { id: eventId },
    registrations: {
      create: [],
      business: [],
      stand_alone_upgrades: [],
    },
    coupon: null,
    invitation_code: null,
    participant: {
      ...initialParticipantData,
    },
    team: null,
    invoice: {
      ...emptyInvoiceDetails,
    },
    payment_method: {
      payment_method: null,
      issuer: null,
    },
    down_payment: false,
    fields: [],
    ...(usePersistedState && persistedState.form),
    ...(coupon && { coupon }),
    ...(invitationCode && { invitation_code: invitationCode }),
    ...(charityId && { charity: { id: charityId } }),
    ...extendedForm,
  };

  const [form, setForm] = useState(initialForm);

  const [session, setSession] = useState<Session>({
    activeRegistration: 0,
    invoiceDetails: false,
    agreedToTerms: false,
    donate: {},
    charities: {},
    touched: {},
    queueToken: null,
    showTimers: false,
    ...usePersistedState ? persistedState.session : {},
    ...extendedSession,
  });

  useDebouncedEffect(() => {
    SessionStorage.setItem(eventId, JSON.stringify({
      form,
      session,
    }));
  }, 250);

  const promotionIds = useMemo(() => getSelectedPromotionIds(form), [form]);

  const setQueueToken = useCallback(
    (queueToken: string) => {
      setSession((session) => ({
        ...session,
        queueToken,
        showTimers: true,
      }));
    },
    [setSession],
  );

  const touch = useCallback(
    (key: string) => {
      setSession((session) => {
        if (!get(session.touched, key)) {
          // Only return a fresh session object if something actually changes
          return {
            ...session,
            touched: set(session.touched, key, true),
          };
        }

        return session;
      });
    },
    [setSession],
  );

  const untouch = useCallback(
    (key: string | string[]) => {
      setSession((session) => {
        if (get(session.touched, key)) {
          // Only return a fresh session object if something actually changes
          return {
            ...session,
            touched: omit(session.touched, key),
          };
        }

        return session;
      });
    },
    [setSession],
  );

  const touched = useCallback(
    (key?: string) => (key ? get(session.touched, key) : session.touched),
    [session],
  );

  /**
   * Removes no longer existing tickets and products from the form state.
   * Self-assigns the registration if there is only one.
   */
  const cleanState = useCallback((event: Event) => {
    // If a ticket category is present in the URL, the user may not select ticket from other categories.
    const ticketCategory = getTicketCategory(event);

    setForm((form) => cleanFormState(form, event, ticketCategory));

    // Reset validation messages of registration details, fields, etc.
    untouch('registrations');
  }, [getTicketCategory, setForm, untouch]);

  const setActiveRegistration = useCallback(
    (index: number) => {
      setSession((session) => ({
        ...session,
        activeRegistration: index,
      }));
    },
    [setSession],
  );

  const setRegistrations = useCallback(
    (
      update: Update<CreateRegistrationInput[]>,
      event: Event,
    ) => {
      setForm((form) => {
        const newRegistrations = update(form.registrations.create);

        // If a ticket category is present in the URL, the user may not select ticket from other categories.
        const ticketCategory = getTicketCategory(event);

        return cleanFormState({
          ...form,
          registrations: {
            ...form.registrations,
            create: newRegistrations,
          },
        }, event, ticketCategory);
      });

      // Reset validation messages of registration details, fields, etc.
      untouch('registrations');
    },
    [setForm, getTicketCategory, untouch],
  );

  const setBusinessRegistrations = useCallback(
    (
      update: Update<BusinessRegistrationInput[]>,
      event: Event,
    ) => {
      setForm((form) => {
        const newRegistrations = update(form.registrations.business);

        // If a ticket category is present in the URL, the user may not select ticket from other categories.
        const ticketCategory = getTicketCategory(event);

        return cleanFormState({
          ...form,
          registrations: {
            ...form.registrations,
            business: newRegistrations,
          },
        }, event, ticketCategory);
      });

      // Reset validation messages of registration details, fields, etc.
      untouch('registrations');
    },
    [setForm, getTicketCategory, untouch],
  );

  const setTicketUpgrades = useCallback(
    (
      update: Update<CreateUpgradeInput[]>,
      registrationIndex: number,
      event: Event,
    ) => {
      setForm((form) => (
        addStandAloneFees(setFieldEntries({
          ...form,
          registrations: {
            ...form.registrations,
            create: form.registrations.create.map((registration, index) => {
              if (index === registrationIndex) {
                const newUpgrades = update(registration.upgrades);

                return {
                  ...registration,
                  upgrades: newUpgrades,
                };
              }

              return registration;
            }),
          },
        }, event), event)
      ));

      // Reset validation messages of upgrade fields
      untouch(`registrations.create.${registrationIndex}.upgrades`);
    },
    [setForm, untouch],
  );

  const setStandAloneUpgrades = useCallback(
    (
      update: (upgrades: CreateUpgradeInput[]) => CreateUpgradeInput[],
      event: Event,
    ) => {
      setForm((form) => (
        addStandAloneFees(setFieldEntries({
          ...form,
          registrations: {
            ...form.registrations,
            stand_alone_upgrades: update(form.registrations.stand_alone_upgrades),
          },
        }, event), event)
      ));

      // Reset validation messages of stand-alone upgrade fields
      untouch('stand_alone_upgrades');
    },
    [setForm, untouch],
  );

  const rememberDonationChoice = useCallback(
    (product: Product, donate?: boolean) => {
      setSession((session) => ({
        ...session,
        donate: {
          ...session.donate,
          [product.id]: donate,
        },
      }));
    },
    [setSession],
  );

  const rememberCharity = useCallback(
    (charity: Charity) => {
      setSession((session) => ({
        ...session,
        charities: {
          ...session.charities,
          [charity.id]: charity,
        },
      }));
    },
    [setSession],
  );

  const editParticipant = useCallback(
    (update: Update<ParticipantInput>) => {
      setForm((form) => ({
        ...form,
        participant: {
          ...initialParticipantData,
          ...update(form.participant),
        },
      }));
    },
    [setForm],
  );

  const editRegistration = useCallback(
    (
      update: Update<CreateRegistrationInput>,
      registrationIndex: number,
      event: Event,
    ) => {
      setForm((form) => prefillCountry(setFieldEntries({
        ...form,
        registrations: {
          ...form.registrations,
          create: form.registrations.create.map((registration, index) => {
            if (index === registrationIndex) {
              return update(registration);
            }

            return registration;
          }),
        },
      }, event), event));
    },
    [setForm],
  );

  const editTicketUpgrade = useCallback(
    (update: Update<CreateUpgradeInput>, registrationIndex: number, upgradeIndex: number) => {
      setForm((form) => ({
        ...form,
        registrations: {
          ...form.registrations,
          create: form.registrations.create.map((registration, index) => {
            if (index === registrationIndex) {
              return {
                ...registration,
                upgrades: registration.upgrades.map((upgrade, index) => {
                  if (index === upgradeIndex) {
                    return update(upgrade);
                  }

                  return upgrade;
                }),
              };
            }

            return registration;
          }),
        },
      }));
    },
    [setForm],
  );

  const editBusinessRegistration = useCallback(
    (update: Update<BusinessRegistrationInput>, registrationIndex: number) => {
      setForm((form) => {
        const registration = form.registrations.business[registrationIndex];
        const newRegistration = update(registration);

        return {
          ...form,
          registrations: {
            ...form.registrations,
            business: form.registrations.business.map((registration, index) => (
              index === registrationIndex ? newRegistration : registration
            )),
          },
        };
      });
    },
    [setForm],
  );

  const editStandAloneUpgrade = useCallback(
    (update: Update<CreateUpgradeInput>, upgradeIndex: number) => {
      setForm((form) => ({
        ...form,
        registrations: {
          ...form.registrations,
          stand_alone_upgrades: form.registrations.stand_alone_upgrades.map((upgrade, index) => (
            index === upgradeIndex ? update(upgrade) : upgrade
          )),
        },
      }));
    },
    [setForm],
  );

  const editOrderField = useCallback(
    (update: Update<ParticipantFieldEntryInput>, fieldIndex: number) => {
      setForm((form) => ({
        ...form,
        fields: form.fields.map((field, index) => (
          index === fieldIndex ? update(field) : field
        )),
      }));
    },
    [setForm],
  );

  const editTeam = useCallback(
    (update: Update<TeamInput>) => {
      setForm((form) => ({
        ...form,
        team: update(form.team),
      }));
    },
    [setForm],
  );

  const setCharity = useCallback(
    (charity: Charity | null) => {
      setForm((form) => ({
        ...form,
        charity: charity ? { id: charity.id } : null,
      }));
    },
    [setForm],
  );

  const setInvitationCode = useCallback(
    (invitationCode: string | null) => {
      setForm((form) => ({
        ...form,
        invitation_code: invitationCode,
      }));

      setUrlState((params) => ({ ...params, invitationCode: invitationCode || undefined }), true);
    },
    [setForm, setUrlState],
  );

  const setCoupon = useCallback(
    (coupon: string | null) => {
      setForm((form) => ({
        ...form,
        coupon,
      }));
    },
    [setForm],
  );

  const toggleInvoice = useCallback(
    () => {
      setSession((session) => ({
        ...session,
        invoiceDetails: !session.invoiceDetails,
      }));

      // Reset validation messages of invoice details
      untouch('invoice');
    },
    [setSession, untouch],
  );

  const editInvoiceDetails = useCallback(
    (update: Update<InvoiceDetailsInput>) => {
      setForm((form) => ({
        ...form,
        invoice: update(form.invoice),
      }));
    },
    [setForm],
  );

  const toggleDownPayment = useCallback(
    (enable: boolean) => {
      setForm((form) => ({
        ...form,
        down_payment: enable,
      }));

      touch('down_payment');
    },
    [setForm, touch],
  );

  const editPaymentMethod = useCallback(
    (update: (paymentMethod: PaymentMethodInput) => PaymentMethodInput) => {
      setForm((form) => ({
        ...form,
        payment_method: update(form.payment_method),
      }));
    },
    [setForm],
  );

  const toggleMailOptIn = useCallback(
    (id: string) => {
      editParticipant((participant) => ({
        ...participant,
        mail_opt_ins: participant.mail_opt_ins.includes(id)
          ? participant.mail_opt_ins.filter((permissionId) => permissionId !== id)
          : [...participant.mail_opt_ins, id],
      }));

      touch('participant.mail_opt_ins');
    },
    [editParticipant, touch],
  );

  const toggleTerms = useCallback(
    () => {
      setSession((session) => ({
        ...session,
        agreedToTerms: !session.agreedToTerms,
      }));

      touch('terms');
    },
    [setSession, touch],
  );

  const history = useHistory();

  /**
   * When params in the URL change, update the state accordingly,
   */
  useEffect(() => history.listen((location, action) => {
    if (action !== 'REPLACE') {
      // Ignore 'replace' actions to prevent loops
      const { invitationCode, coupon } = parseUrlState(location.search);

      if (invitationCode) {
        setInvitationCode(invitationCode);
      }

      if (coupon) {
        setCoupon(coupon);
      }
    }
  }), [history, parseUrlState, setInvitationCode, setCoupon]);

  const context = {
    form,
    session,
    promotionIds,
    setQueueToken,
    setRegistrations,
    setBusinessRegistrations,
    cleanState,
    setTicketUpgrades,
    setStandAloneUpgrades,
    rememberDonationChoice,
    rememberCharity,
    editParticipant,
    editRegistration,
    editTicketUpgrade,
    editBusinessRegistration,
    editStandAloneUpgrade,
    editOrderField,
    editTeam,
    setActiveRegistration,
    setCharity,
    setInvitationCode,
    setCoupon,
    toggleInvoice,
    editInvoiceDetails,
    editPaymentMethod,
    toggleDownPayment,
    toggleMailOptIn,
    toggleTerms,
    touch,
    untouch,
    touched,
  };

  return (
    <StateContext.Provider value={context}>
      {children}
    </StateContext.Provider>
  );
};

/**
 * These attributes are required in the form for the form to work properly.
 */
export const initialParticipantData = {
  mail_opt_ins: [] as string[],
};

// eslint-disable-next-line max-len
type Pipe = (form: CompleteCheckoutInput, event: Event, ticketCategory?: TicketCategory | null) => CompleteCheckoutInput;

/**
 * Performs a full clean-up of the form state.
 */
const cleanFormState = (form: CompleteCheckoutInput, event: Event, ticketCategory: TicketCategory | null) => {
  // The order of these pipes is important.
  const pipes: Pipe[] = [
    filterSellables,
    addStandAloneFees,
    sortRegistrations,
    setFieldEntries,
    prefillCountry,
    cleanCharity,
    cleanTeam,
  ];

  let result = form;

  pipes.forEach((pipe) => {
    result = pipe(result, event, ticketCategory);
  });

  return result;
};

/**
 * Removes tickets and upgrades from the form state that are no longer available or sold out.
 */
export const filterSellables = (
  form: CompleteCheckoutInput,
  event: Event,
  ticketCategory?: TicketCategory | null,
) => {
  /**
   * Loop through the form's registrations and upgrades and add them one by one,
   * and checking the availability as if the user is pressing + in the checkout.
   */
  const validRegistrations: CreateRegistrationInput[] = [];
  const validBusinessRegistrations: BusinessRegistrationInput[] = [];
  const validStandAloneUpgrades: CreateUpgradeInput[] = [];

  const tickets = ticketCategory?.tickets_for_sale || getTicketsForSale(event);
  const keyedTickets = getKeyedSellables(tickets);
  const keyedProducts = getKeyedSellables(event.products_for_sale);
  const keyedTimeSlots = getKeyedTimeSlots(tickets);
  const keyedProductVariants = getKeyedProductVariants(event.products_for_sale);
  const selectedTickets: Ticket[] = [];
  const selectedProducts: Product[] = [];

  /**
   * Check that the ticket and time slot still exist,
   * and calculate the availability based on the 'valid' registrations.
   * The onAvailable callback is executed when the availability is at least 1.
   */
  const checkTicketAvailability = (
    promotionId: string,
    timeSlotId: string | null,
    onAvailable: (availability: number) => void,
  ) => {
    const ticket = keyedTickets[promotionId];
    const timeSlot = keyedTimeSlots[timeSlotId];

    if (!ticket || (timeSlotId && !timeSlot)) {
      // Ticket or time slot is not available anymore.
      return;
    }

    const quantities = calculateQuantities({
      registrations: validRegistrations,
      businessRegistrations: validBusinessRegistrations,
      standAloneUpgrades: validStandAloneUpgrades,
      ticketCategories: event.ticket_categories,
    });

    const availability = calculateAvailability({
      quantities,
      ticketCategories: event.ticket_categories,
    });

    const ticketAvailability = timeSlotId
      ? availability.timeSlots[promotionId][timeSlotId]
      : availability.promotions[promotionId];

    if (ticketAvailability > 0) {
      // Promotion and time slot are available.
      onAvailable(ticketAvailability);
    }
  };

  /**
   * Check that the upgrade's product and product variant still exist,
   * and calculate the availability based on the 'valid' registrations.
   * The onAvailable callback is executed when the availability is at least 1.
   */
  const checkUpgradeAvailability = (
    upgrade: CreateUpgradeInput,
    products: Product[],
    onAvailable: (availability: number) => void,
  ) => {
    const keyedProducts = getKeyedSellables(products);
    const promotionId = upgrade.purchase.promotion.id;
    const productVariantId = upgrade.product_variant?.id;

    const product = keyedProducts[promotionId];
    const productVariant = keyedProductVariants[productVariantId];
    if (!product || (productVariantId && !productVariant)) {
      // Product or variant is not available anymore.
      return;
    }

    /** The selected quantities of this upgrade. */
    const quantities = calculateQuantities({
      registrations: validRegistrations,
      businessRegistrations: validBusinessRegistrations,
      standAloneUpgrades: validStandAloneUpgrades,
      // ticket.units is required for calculating quantities of business upgrades.
      ticketCategories: event.ticket_categories,
      products: [product],
    });

    /** The remaining availability of this upgrade.  */
    const availability = calculateAvailability({
      quantities,
      products: [product],
    });

    const productAvailability = productVariantId
      ? availability.productVariants[promotionId][productVariantId]
      : availability.promotions[promotionId];

    if (productAvailability > 0) {
      // Promotion and product variant are available.
      onAvailable(productAvailability);
    }
  };

  // Filter out any tickets and products that are no longer available
  form.registrations.create.forEach((registration) => {
    const promotionId = registration.purchase.promotion.id;
    const timeSlotId = registration.time_slot?.id;

    checkTicketAvailability(promotionId, timeSlotId, () => {
      const validRegistration: CreateRegistrationInput = {
        ...registration,
        // The upgrades will be added in the next step.
        upgrades: [],
      };

      validRegistrations.push(validRegistration);

      const ticket = keyedTickets[promotionId];
      selectedTickets.push(ticket);

      registration.upgrades.forEach((upgrade) => {
        const products = getProductsForTicket(ticket, event.products_for_sale);

        checkUpgradeAvailability(upgrade, products, () => {
          validRegistration.upgrades.push(upgrade);

          const product = keyedProducts[upgrade.purchase.promotion.id];
          selectedProducts.push(product);
        });
      });
    });
  });

  // Filter out any business tickets that are no longer available
  form.registrations.business.forEach((registration) => {
    const promotionId = registration.promotion.id;
    const timeSlotId = registration.time_slot?.id;

    checkTicketAvailability(promotionId, timeSlotId, (availability) => {
      validBusinessRegistrations.push({
        ...registration,
        // Reduce the quantity when the availability has changed.
        quantity: Math.min(registration.quantity, availability),
        // The upgrades will be added in the next step.
        upgrades: [],
      });

      selectedTickets.push(keyedTickets[promotionId]);
    });
  });

  // Filter out any business upgrades that are no longer available
  const businessTickets = tickets.filter((ticket) => ticket.business) || [];

  businessTickets.forEach((ticket) => {
    /** All of the current ticket's promotion IDs */
    const ticketPromotionIds = ticket.promotions_for_sale.map(({ id }) => id);

    /** All business registrations with the same ticket. */
    const businessRegistrations = validBusinessRegistrations.filter((registration) => (
      ticketPromotionIds.includes(registration.promotion.id)
    ));

    /** The business upgrades that are currently selected. */
    const upgrades = form.registrations.business.filter((registration) => (
      ticketPromotionIds.includes(registration.promotion.id)
    ))[0]?.upgrades || [];

    upgrades.forEach((upgrade) => {
      /** The products that can be chosen with the current ticket. */
      const products = getProductsForTicket(ticket, event.products_for_sale);

      checkUpgradeAvailability(upgrade, products, (availability) => {
        /** The total quantity for the current ticket. */
        const ticketQuantity = businessRegistrations.reduce((quantity, registration) => (
          quantity + registration.quantity
        ), 0);

        const units = ticketQuantity * ticket.units;

        if (availability >= units) {
          businessRegistrations.forEach((registration) => {
            registration.upgrades.push(upgrade);

            const product = keyedProducts[upgrade.purchase.promotion.id];
            selectedProducts.push(product);
          });
        }
      });
    });
  });

  // Filter out any stand-alone products that are no longer available
  const standAloneUpgrades = form.registrations.stand_alone_upgrades.filter((upgrade) => (
    keyedProducts[upgrade.purchase.promotion.id] && !keyedProducts[upgrade.purchase.promotion.id].is_ticket_fee
  ));
  const standAloneFees = form.registrations.stand_alone_upgrades.filter((upgrade) => (
    keyedProducts[upgrade.purchase.promotion.id]?.is_ticket_fee
  ));

  // Fees last, because they can depend on other stand alone upgrades
  [...standAloneUpgrades, ...standAloneFees].forEach((upgrade) => {
    const products = getStandAloneProducts(selectedTickets, selectedProducts, event.products_for_sale);

    checkUpgradeAvailability(upgrade, products, () => {
      validStandAloneUpgrades.push(upgrade);

      const product = keyedProducts[upgrade.purchase.promotion.id];
      selectedProducts.push(product);
    });
  });

  return {
    ...form,
    registrations: {
      ...form.registrations,
      create: validRegistrations,
      business: validBusinessRegistrations,
      stand_alone_upgrades: validStandAloneUpgrades,
    },
  };
};

export const addStandAloneFees = (form: CompleteCheckoutInput, event: Event) => {
  const promotionIds = getSelectedPromotionIds(form);
  const keyedTickets = getKeyedSellables(getTicketsForSale(event));
  const keyedProducts = getKeyedSellables(event.products_for_sale);
  const selectedTickets = uniq(promotionIds.map((id) => keyedTickets[id]).filter((ticket) => ticket));
  const selectedProducts = uniq(promotionIds.map((id) => keyedProducts[id]).filter((product) => product));
  const feeProducts = getStandAloneProducts(selectedTickets, selectedProducts, event.products_for_sale).filter(
    (product) => product.is_ticket_fee,
  );

  // Keep all selected stand-alone upgrades (except fees).
  const standAloneUpgrades = form.registrations.stand_alone_upgrades.filter((upgrade) => (
    !keyedProducts[upgrade.purchase.promotion.id].is_ticket_fee
  ));

  // Add stand-alone fees.
  const standAloneFees = feeProducts.map((product) => ({
    product: { id: product.id },
    purchase: { promotion: { id: product.promotions_for_sale[0].id } },
    fields: [],
  }));

  return {
    ...form,
    registrations: {
      ...form.registrations,
      stand_alone_upgrades: [
        ...standAloneUpgrades,
        ...standAloneFees,
      ],
    },

  };
};

/**
 * Sorts the list of registrations according to the list of tickets.
 */
export const sortRegistrations = (form: CompleteCheckoutInput, event: Event) => {
  const promotionIds: string[] = [];
  const timeSlotIds: string[] = [];

  getTicketsForSale(event).forEach((ticket) => {
    promotionIds.push(...map(ticket.promotions_for_sale, 'id'));
    timeSlotIds.push(...map(ticket.upcoming_time_slots || [], 'id'));
  });

  return {
    ...form,
    registrations: {
      ...form.registrations,
      create: sortBy(form.registrations.create, [
        (registration) => promotionIds.indexOf(registration.purchase.promotion.id),
        (registration) => timeSlotIds.indexOf(registration.time_slot?.id),
      ]),
    },
  };
};

/**
 * Goes through the entire form state and creates and prefills participant field entries where necessary:
 * - form.registrations.create[].fields
 * - form.registrations.create[].upgrades[].fields
 * - form.fields
 * Keeps existing values and removes field entries that are not present in the given event's participant fields.
 *
 * Note: this does not set the field-entries itself, but transforms the input such to the appropriate output
 * for `setForm` to set these entries.
 */
export const setFieldEntries = (
  form: CompleteCheckoutInput, event: Event,
) => {
  const promotionIds = getSelectedPromotionIds(form);

  return {
    ...form,
    registrations: {
      ...form.registrations,
      create: form.registrations.create.map((registration) => ({
        ...registration,
        fields: registration.participant
          ? prefillFieldEntries(
            registration.fields,
            getPromotionFields(event.enabled_participant_fields, registration.purchase.promotion.id),
          )
          : [],
        upgrades: registration.upgrades.map((upgrade) => ({
          ...upgrade,
          fields: prefillFieldEntries(
            upgrade.fields,
            getPromotionFields(event.enabled_participant_fields, upgrade.purchase.promotion.id),
          ),
        })),
      })),
      stand_alone_upgrades: form.registrations.stand_alone_upgrades.map((upgrade) => ({
        ...upgrade,
        fields: prefillFieldEntries(
          upgrade.fields,
          getPromotionFields(event.enabled_participant_fields, upgrade.purchase.promotion.id),
        ),
      })),
    },
    fields: prefillFieldEntries(form.fields, getOrderFields(event.enabled_participant_fields, promotionIds)),
  };
};

/**
 * Prefills the country of the invoice details and all assigned registrations using the country of the project.
 */
const prefillCountry = (form: CompleteCheckoutInput, event: Event) => ({
  ...form,
  invoice: form.invoice ? {
    ...form.invoice,
    country: form.invoice.country || event.project.organisation_country,
  } : form.invoice,
  fields: form.fields.map((field) => prefillFieldCountry(field, event)),
  registrations: {
    ...form.registrations,
    create: form.registrations.create.map(
      (registration) => prefillRegistrationCountry(registration, event),
    ),
  },
});

const prefillFieldCountry = (fieldEntry: ParticipantFieldEntryInput, event: Event): ParticipantFieldEntryInput => {
  const field = event.enabled_participant_fields.find(
    (participantField) => participantField.id === fieldEntry.participant_field.id,
  );

  if (!field) {
    return fieldEntry;
  }

  if (field.type === ParticipantFieldType.address) {
    const parsedValue: AddressValue = fieldEntry.value ? JSON.parse(fieldEntry.value) : { enabled: field.required };

    return {
      ...fieldEntry,
      value: JSON.stringify({
        ...parsedValue,
        country: parsedValue.country || event.project.organisation_country,
      }),
    };
  }

  return fieldEntry;
};

const prefillRegistrationCountry = (
  registration: CreateRegistrationInput, event: Event,
) => {
  if (registration.participant) {
    const defaultCountry = event.project.organisation_country;
    const participantAttributes = getRequiredParticipantAttributes(registration, event);
    const requireCountry = participantAttributes.includes(ParticipantAttributes.address);
    const requireNationality = participantAttributes.includes(ParticipantAttributes.nationality);

    return {
      ...registration,
      fields: registration.fields.map((field) => prefillFieldCountry(field, event)),
      details: registration.details ? {
        country: registration.details.country || (requireCountry ? defaultCountry : null),
        nationality: registration.details.nationality || (requireNationality ? defaultCountry : null),
        ...registration.details,
      } : null,
    };
  }

  return registration;
};

/**
 * Prefills the charity object of fundraising tickets.
 */
const cleanCharity = (form: CompleteCheckoutInput, event: Event) => {
  if (!event.enable_fundraising) {
    return {
      ...form,
      charity: null,
    };
  }

  return form;
};

/**
 * Removes the team if there is one on the activated invitation code.
 */
export const cleanTeam = (form: CompleteCheckoutInput, event: Event) => {
  if (event.invitation_code?.invitation.team) {
    // May not create or join a team if a team is present on used invitation
    return {
      ...form,
      team: null,
    };
  }

  // Do not take into account business tickets (team is created based on company name)
  const quantities = calculateQuantities({
    registrations: form.registrations.create,
    ticketCategories: event.ticket_categories,
  });

  const tickets = getTicketsForSale(event);

  const { allowIndividuals, allowCreateTeam, allowJoinTeam } = getTeamFlags(
    tickets.filter((ticket) => quantities.tickets[ticket.id] > 0) || [],
  );

  if (!allowCreateTeam && !allowJoinTeam) {
    // May not create or join a team
    return {
      ...form,
      team: null,
    };
  }

  if (!allowIndividuals && !form.team?.id) {
    // Must join an existing team
    return {
      ...form,
      team: { id: null },
    };
  }

  return form;
};

/**
 * Extracts all promotion IDs that are present in the form state (normal tickets, business tickets and products).
 */
const getSelectedPromotionIds = (form: CompleteCheckoutInput) => [
  // Registrations + ticket upgrades
  ...form.registrations.create.reduce((promotionIds, registration) => [
    ...promotionIds,
    registration.purchase.promotion.id,
    ...registration.upgrades.map(({ purchase }) => purchase.promotion.id),
  ], [] as string[]),
  // Business registrations
  ...form.registrations.business.map(({ promotion }) => promotion.id),
  // Stand-alone upgrades
  ...form.registrations.stand_alone_upgrades.map((upgrade) => upgrade.purchase.promotion.id),
];

export default StateProvider;
