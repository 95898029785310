import { ArrowLeft, ArrowRight, ChevronDown, ChevronUp, Lock, ShoppingBag } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';

import { CheckoutStep } from './helpers';
import { EmbedContext } from '../Common/EmbedProvider';
import { containsKeyPrefix, filterServerErrors } from '../../common/helpers';
import { useCheckoutSummary } from './CheckoutSummaryProvider';
import { useEvent } from './EventProvider';
import CheckoutContext from './CheckoutContext';
import CheckoutSummary from './CheckoutSummary';
import PagerContainer from '../Common/PagerContainer';
import UI from '../../common/UI';
import config from '../config';
import useLocale from '../../common/useLocale';
import useMediaDevice from '../../common/useMediaDevice';
import useStickyElement from '../../common/useStickyElement';

export interface PagerProps {
  checkoutSteps: CheckoutStep[];
  activeStepIndex: number;
  loading?: boolean;
  slowFetchTimeout?: number
}

/**
 * Shows the Next button and shopping basket. During personalisation, it also shows a Previous button.
 * In the last step, it shows the Pay/Register button.
 */
const Pager = ({ checkoutSteps, activeStepIndex, loading, slowFetchTimeout = 3000 }: PagerProps) => {
  const { t } = useTranslation();
  const { formatCurrency, locale } = useLocale();
  const { embedded, scrollTop } = useContext(EmbedContext);
  const device = useMediaDevice();

  const { event } = useEvent();
  const { checkoutSummary, loading: refetching } = useCheckoutSummary();

  const activeStepName = checkoutSteps[activeStepIndex];

  const {
    form,
    setActiveRegistration,
    validators,
    personalisation,
  } = useContext(CheckoutContext);

  const validator = validators[activeStepName];

  const valid = activeStepName === CheckoutStep.Personalisation ? (
    !containsKeyPrefix(filterServerErrors(validator.errors), `registrations.create.${personalisation.activeFormIndex}`)
    && validator.validating.filter((attribute) => attribute.startsWith(`registrations.create.${personalisation.activeFormIndex}`)).length === 0
  ) : validator.valid;

  /** All product purchases excluding ticket fees */
  const products = checkoutSummary.products.filter(({ purchase }) => !purchase.product.is_ticket_fee) || [];

  const quantity = [...checkoutSummary.tickets || [], ...products]
    .reduce((sum, purchase) => sum + purchase.quantity, 0);

  const { anchorRef, elementRef, isSticky, position, reset: resetStickyElement } = useStickyElement({
    position: 'bottom',
    containerId: 'CheckoutContainer',
    scrollContainerId: embedded && !device.isSmall ? 'PageContainer' : undefined,
  });

  const showTotals = activeStepName !== CheckoutStep.Payment;

  const showBackButton = activeStepName === CheckoutStep.Personalisation
    && form.registrations.create.length > 1 && personalisation.activeIndex > 0;

  const prevStep = () => {
    setActiveRegistration(personalisation.prevIndex);
    scrollTop();
  };

  const [showSummary, setShowSummary] = useState(false);

  const amount = ((form.down_payment ? checkoutSummary.down_payment_amount : checkoutSummary.amount) || 0)
    + checkoutSummary.passed_on_fee;

  useEffect(() => {
    resetStickyElement();
  }, [showSummary, showTotals, resetStickyElement]);

  const [showSlowFetchAlert, setShowSlowFetchAlert] = useState(false);

  const unassignedTicketCount = form.registrations.create.filter((registration, index) => (
    !registration.participant || !personalisation.personalisedFormIndices.includes(index)
  )).length;

  useEffect(() => {
    if (refetching) {
      // If fetching the checkout summary takes more than 2 seconds, show a message to the user.
      const timer = window.setTimeout(() => {
        setShowSlowFetchAlert(true);
      }, slowFetchTimeout);

      return () => window.clearTimeout(timer);
    }

    setShowSummary(false);
    setShowSlowFetchAlert(false);

    return undefined;
  }, [refetching, slowFetchTimeout]);

  return (
    <UI.Div sc={{ padding: 0 }} ref={anchorRef}>
      <PagerContainer ref={elementRef} isSticky={isSticky && showTotals} isEmbedded={embedded} {...position}>
        <UI.GridContainer>
          {!showTotals && (
            <>
              {unassignedTicketCount > 0 && (
                <UI.Info>
                  <UI.Div>
                    <UI.Strong sc={{ block: true }}>
                      {t('unassigned_tickets_warning', { count: unassignedTicketCount })}
                    </UI.Strong>
                    {t('unassigned_tickets_description', { count: unassignedTicketCount })}
                  </UI.Div>
                </UI.Info>
              )}
              <UI.Div>
                <Trans
                  i18nKey="common:terms.terms_notice"
                  values={{
                    action: amount > 0 ? t('pay') : t('register'),
                  }}
                >
                  <UI.A href={config.termsUrl[locale]} target="_blank" rel="noopener noreferer" />
                </Trans>
              </UI.Div>
            </>
          )}
          {checkoutSummary && showSummary && showTotals && (
            <UI.FadeIn>
              <CheckoutSummary event={event} summary={checkoutSummary} loading={refetching} />
            </UI.FadeIn>
          )}
          <UI.FlexContainer sc={{ alignItems: 'center', justifyContent: 'space-between' }}>
            {showSlowFetchAlert && (
              <UI.Div>
                {t('slow_fetch_message')}
              </UI.Div>
            )}
            {!showSlowFetchAlert && showTotals && (
              <UI.Div sc={{ muted: refetching }}>
                <UI.A
                  onClick={() => setShowSummary((show) => !show)}
                  sc={{ secondary: true, disabled: quantity === 0, noWrap: true }}
                  aria-label={t('pager.shopping_cart', { count: quantity })}
                  role="button"
                >
                  <UI.Icon>
                    <ShoppingBag />
                  </UI.Icon>
                  {' '}
                  {quantity}
                  {' '}
                  <UI.Icon sc={{ muted: true }}>
                    {showSummary ? <ChevronDown /> : <ChevronUp />}
                  </UI.Icon>
                </UI.A>
              </UI.Div>
            )}
            <UI.Div sc={{ width: !showTotals && !showSlowFetchAlert ? '100%' : 'auto' }}>
              {!showSlowFetchAlert && showTotals && !showSummary && (
                <UI.Strong sc={{ color: 'gray.800', mr: 3, muted: refetching }}>
                  {formatCurrency(amount)}
                </UI.Strong>
              )}
              {!showSlowFetchAlert && showBackButton && (
                <UI.Button
                  onClick={() => prevStep()}
                  sc={{ mr: 1 }}
                  aria-label={t('pager.previous')}
                >
                  <UI.Icon>
                    <ArrowLeft />
                  </UI.Icon>
                </UI.Button>
              )}
              <UI.Button
                type="submit"
                sc={{
                  brand: 'secondary',
                  block: !showTotals,
                  loading: loading || refetching,
                  noWrap: true,
                }}
                disabled={loading || refetching || !valid}
                key={activeStepName}
              >
                {activeStepName === CheckoutStep.Payment ? (
                  <>
                    <UI.Icon>
                      {(loading || showSlowFetchAlert) ? (
                        <UI.Loader sc={{ brand: 'white' }} />
                      ) : (
                        <Lock />
                      )}
                    </UI.Icon>
                    {' '}
                    {amount > 0 ? t('pay') : t('register')}
                  </>
                ) : (
                  <>
                    {activeStepName === CheckoutStep.Fundraising && !form.charity && !event.require_fundraising
                      ? t('pager.skip')
                      : t('pager.next')}
                    {' '}
                    <UI.Icon>
                      {showSlowFetchAlert && <UI.Loader sc={{ brand: 'white' }} />}
                      {!showSlowFetchAlert && <ArrowRight />}
                    </UI.Icon>
                  </>
                )}
              </UI.Button>
            </UI.Div>
          </UI.FlexContainer>
        </UI.GridContainer>
      </PagerContainer>
    </UI.Div>
  );
};

export default Pager;
