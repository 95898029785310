import { ArrowUpRight } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import keyBy from 'lodash/keyBy';

import { CheckoutStep, getRequiredParticipantAttributes } from '../helpers';
import { EmbedContext } from '../../Common/EmbedProvider';
import { containsKeyPrefix } from '../../../common/helpers';
import { useCheckoutSummary } from '../CheckoutSummaryProvider';
import { useEvent } from '../EventProvider';
import CheckoutContext from '../CheckoutContext';
import CheckoutSummary from '../CheckoutSummary';
import CouponInput from './CouponInput';
import InvoiceDetails from '../../../common/Invoice/InvoiceDetails';
import ParticipantFieldValue from '../../../common/ParticipantFields/ParticipantFieldValue';
import PaymentMethodSelection from '../../../common/Payments/PaymentMethodSelection';
import RegistrationAttribute from '../../../common/Registrations/RegistrationAttribute';
import UI from '../../../common/UI';
import useLocale from '../../../common/useLocale';

const PaymentForm = () => {
  const { t } = useTranslation();
  const { formatCurrency } = useLocale();

  const { event } = useEvent();
  const { checkoutSummary, loading } = useCheckoutSummary();

  const {
    form,
    personalisation: {
      personalRegistrations,
      buyer,
    },
    validators: { [CheckoutStep.Payment]: { errors, valid } },
    session,
    paymentMethods: {
      allowedPaymentMethods,
    },
    requireInvoiceDetails,
    setCoupon,
    toggleDownPayment,
    editPaymentMethod,
    toggleMailOptIn,
    toggleTerms,
    touch,
    touched,
  } = useContext(CheckoutContext);

  const participantAttributes = personalRegistrations.length > 0
    ? getRequiredParticipantAttributes(personalRegistrations[0], event)
    : [];

  const team = form.team || event.invitation_code?.invitation.team;

  const keyedFields = keyBy(event.enabled_participant_fields, 'id');
  const acceptDownPayment = checkoutSummary.down_payment_amount < checkoutSummary.amount;
  const requirePayment = checkoutSummary.amount > 0;

  const couponCode = checkoutSummary.coupon_code;
  const showCouponInput = requirePayment || form.coupon || couponCode;

  // Unset the selected payment method when the amount becomes zero. For example, after entering a coupon code.
  useEffect(() => {
    if (!loading && !requirePayment) {
      editPaymentMethod(() => ({
        payment_method: null,
        issuer: null,
      }));
    }
  }, [loading, requirePayment, editPaymentMethod]);

  const termsText = event.terms_text;
  const termsUrl = event.terms_url || event.terms?.url;
  const requireTerms = termsText || termsUrl;

  const hasInvoiceError = containsKeyPrefix(errors, 'invoice');
  const hasPaymentMethodError = containsKeyPrefix(errors, 'payment_method');
  const hasTermsError = !!errors.terms;

  const { scrollToSection, scrollTop } = useContext(EmbedContext);

  const scrollToErrorFields = () => {
    if (hasInvoiceError) {
      scrollToSection('InvoiceSection');
    } else if (hasPaymentMethodError) {
      scrollToSection('PaymentMethodSection');
    } else if (hasTermsError) {
      scrollToSection('TermsSection');
    } else {
      scrollTop();
    }

    Object.keys(errors).forEach((key: string) => touch(key));
  };

  return (
    <UI.FadeIn>
      <UI.FormGrid>
        <UI.Legend>
          {t('payment_form.your_details')}
        </UI.Legend>
        <UI.FormGrid sc={{ gutter: 0.5 }}>
          <UI.Div>
            <UI.Legend sc={{ fontWeight: 500 }}>{t('payment_form.name')}</UI.Legend>
            {buyer.first_name}
            {' '}
            {buyer.last_name}
          </UI.Div>

          <UI.Div>
            <UI.Legend sc={{ fontWeight: 500 }}>{`${t('payment_form.email_address')}`}</UI.Legend>
            {buyer.email}
          </UI.Div>

          {personalRegistrations.length > 0 && (
            <>
              {participantAttributes.map((attribute) => (
                <RegistrationAttribute
                  registration={personalRegistrations[0].details}
                  attribute={attribute}
                  key={attribute}
                />
              ))}

              {personalRegistrations[0].fields.map((value) => (
                (value.value || value.choices.length > 0)
                  && keyedFields[value.participant_field.id]
                  && (
                    <UI.Div key={value.participant_field.id}>
                      <ParticipantFieldValue
                        fieldEntry={{
                          value: value.value,
                          choice_entries: keyedFields[value.participant_field.id]
                            .enabled_choices.filter((choice) => (
                              value.choices.map((choice) => choice.id).includes(choice.id)
                            )).map((choice) => ({ choice })),
                          field: {
                            id: value.participant_field.id,
                          },
                        }}
                        field={keyedFields[value.participant_field.id]}
                      />
                    </UI.Div>
                  )
              ))}
            </>
          )}

          {form.fields.map((value) => (
            (value.value || value.choices.length > 0)
              && keyedFields[value.participant_field.id]
              && (
                <UI.Div key={value.participant_field.id}>
                  <ParticipantFieldValue
                    fieldEntry={{
                      value: value.value,
                      choice_entries: keyedFields[value.participant_field.id]
                        .enabled_choices.filter((choice) => (
                          value.choices.map((choice) => choice.id).includes(choice.id)
                        )).map((choice) => ({ choice })),
                      field: {
                        id: value.participant_field.id,
                      },
                    }}
                    field={keyedFields[value.participant_field.id]}
                  />
                </UI.Div>
              )
          ))}

          {team && (
            <UI.Div>
              <UI.Legend sc={{ fontWeight: 500 }}>{t('team')}</UI.Legend>
              {team.title}
            </UI.Div>
          )}
        </UI.FormGrid>

        {(session.invoiceDetails || requireInvoiceDetails) && (
          <>
            <UI.HR />

            <UI.Legend id="InvoiceSection">
              {t('invoice_details')}
            </UI.Legend>

            <InvoiceDetails {...form.invoice} />
          </>
        )}

        {(showCouponInput || acceptDownPayment || requirePayment) && (
          <>
            <UI.HR />

            <UI.Legend>
              {t('payment')}
            </UI.Legend>

            {showCouponInput && (
              <CouponInput
                value={form.coupon}
                onChange={(coupon) => setCoupon(coupon)}
                onBlur={() => touch('coupon')}
                checkoutSummary={checkoutSummary}
                loading={loading}
                errors={errors}
                touched={session.touched}
              />
            )}

            {acceptDownPayment && (
              <UI.FadeIn>
                <UI.FormGrid>
                  <UI.InputGroup sc={{ valid: !errors?.down_payment, touched: !!touched('down_payment'), mb: 0 }}>
                    <UI.InputLabel>
                      {t('payment')}
                    </UI.InputLabel>
                    <UI.RadioPill>
                      <UI.Radio
                        onChange={() => toggleDownPayment(false)}
                        checked={!form.down_payment}
                        sc={{ box: true }}
                        style={{ flex: 1 }}
                      >
                        <UI.Strong>{t('payment_form.full_payment')}</UI.Strong>
                        <UI.Div>{formatCurrency(checkoutSummary.amount + checkoutSummary.passed_on_fee)}</UI.Div>
                      </UI.Radio>
                      <UI.Radio
                        onChange={() => toggleDownPayment(true)}
                        checked={form.down_payment}
                        sc={{ box: true }}
                        style={{ flex: 1 }}
                      >
                        <UI.Strong>{t('payment_form.down_payment')}</UI.Strong>
                        <UI.Div>
                          {formatCurrency(checkoutSummary.down_payment_amount + checkoutSummary.passed_on_fee)}
                        </UI.Div>
                      </UI.Radio>
                    </UI.RadioPill>
                    <UI.ErrorMessages attribute={t('payment_form.down_payment')} errors={errors.down_payment} />
                  </UI.InputGroup>
                </UI.FormGrid>
              </UI.FadeIn>
            )}

            {requirePayment && (
              <UI.FadeIn id="PaymentMethodSection">
                <UI.InputGroup sc={{ valid: !hasPaymentMethodError, touched: !!touched('payment_method') }}>
                  <UI.InputLabel>
                    {t('payment_form.payment_method')}
                    {' '}
                    <UI.RequiredMark />
                  </UI.InputLabel>
                  <PaymentMethodSelection
                    value={form.payment_method}
                    onChange={(value) => editPaymentMethod((paymentMethod) => ({ ...paymentMethod, ...value }))}
                    onBlur={(field) => touch(`payment_method.${field}`)}
                    paymentMethods={allowedPaymentMethods}
                  />
                  <UI.ErrorMessages
                    attribute={t('payment_form.payment_method')}
                    errors={errors['payment_method.payment_method']}
                  />
                  <UI.ErrorMessages
                    attribute={t('payment_form.bank')}
                    errors={errors['payment_method.issuer']}
                  />
                </UI.InputGroup>
              </UI.FadeIn>
            )}
          </>
        )}

        <UI.FadeIn>
          <UI.FormGrid>
            <UI.HR />

            <UI.Legend>
              {t('your_order')}
            </UI.Legend>

            <CheckoutSummary
              event={event}
              summary={checkoutSummary}
              downPayment={form.down_payment}
              loading={loading}
            />
          </UI.FormGrid>
        </UI.FadeIn>

        {(event.explicit_mail_permissions.length > 0 || requireTerms || !valid) && (
          <UI.FadeIn>
            <UI.FormGrid>
              <UI.HR />

              {event.explicit_mail_permissions.length > 0 && (
                <UI.InputGroup>
                  <UI.InputLabel>
                    {t('payment_form.email_preferences')}
                  </UI.InputLabel>
                  {event.explicit_mail_permissions.map((mailPermission) => (
                    <UI.Checkbox
                      checked={form.participant.mail_opt_ins.includes(mailPermission.id)}
                      onChange={() => toggleMailOptIn(mailPermission.id)}
                      key={mailPermission.id}
                    >
                      {mailPermission.label}
                    </UI.Checkbox>
                  ))}
                </UI.InputGroup>
              )}

              {requireTerms && (
                <UI.InputGroup id="TermsSection" sc={{ valid: session.agreedToTerms, touched: !!touched('terms') }}>
                  <UI.InputLabel>
                    {t('terms_and_conditions')}
                    {' '}
                    <UI.RequiredMark />
                  </UI.InputLabel>
                  {termsUrl && (
                    <UI.Checkbox
                      checked={session.agreedToTerms}
                      onChange={() => toggleTerms()}
                    >
                      <Trans i18nKey="agree_to_terms">
                        {/* eslint-disable */}
                        <a href={termsUrl} target="_blank" rel="noopener noreferrer" />
                        {/* eslint-enable */}
                      </Trans>
                    </UI.Checkbox>
                  )}
                  {termsText && (
                    <UI.Checkbox
                      checked={session.agreedToTerms}
                      onChange={() => toggleTerms()}
                    >
                      <UI.HTML html={termsText} />
                    </UI.Checkbox>
                  )}
                  <UI.ErrorMessages attribute={t('terms_and_conditions')} errors={errors.terms} />
                </UI.InputGroup>
              )}

              {!valid && (
                <UI.FadeIn>
                  <UI.Warning>
                    <UI.A
                      onClick={() => scrollToErrorFields()}
                      sc={{ secondary: true }}
                      style={{ fontWeight: 500 }}
                      role="button"
                    >
                      {t('fill_required_fields')}
                      {' '}
                      <UI.Icon>
                        <ArrowUpRight />
                      </UI.Icon>
                    </UI.A>
                  </UI.Warning>
                </UI.FadeIn>
              )}
            </UI.FormGrid>
          </UI.FadeIn>
        )}
      </UI.FormGrid>
    </UI.FadeIn>
  );
};

export default PaymentForm;
