import { Check, Percent } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { Charity } from '../../../common/Fundraising/CharityPicker';
import { CouponCode } from '../helpers';
import TicketSelector from './TicketSelector';
import UI from '../../../common/UI';
import useLocale from '../../../common/useLocale';

interface TicketsFormProps {
  preselectedCouponCode?: CouponCode | null;
  preselectedCharity?: Charity | null;
}

const TicketsForm = ({ preselectedCouponCode, preselectedCharity }: TicketsFormProps) => {
  const { t } = useTranslation();
  const { formatCurrency, formatNumber } = useLocale();

  return (
    <>
      {preselectedCouponCode && (
        <UI.GridContainer
          sc={{
            background: 'gray.50',
            columns: '16px 1fr',
            gutter: 0.5,
          }}
        >
          <UI.Div>
            <UI.Icon sc={{ color: 'secondary', mt: -0.25 }}>
              <Percent />
            </UI.Icon>
          </UI.Div>
          <UI.Div>
            <UI.Strong>
              {t('tickets_form.coupon_activated')}
            </UI.Strong>
            <UI.Div>
              {preselectedCouponCode.value}
              {' ('}
              {preselectedCouponCode.coupon.type === 'absolute' && formatCurrency(preselectedCouponCode.coupon.value)}
              {preselectedCouponCode.coupon.type === 'percentage' && `${formatNumber(preselectedCouponCode.coupon.value)}%`}
              )
            </UI.Div>
          </UI.Div>
        </UI.GridContainer>
      )}

      {preselectedCharity && (
        <UI.GridContainer
          sc={{
            background: 'gray.50',
            columns: preselectedCharity.logo_url ? '16px 1fr fit-content(100px)' : '16px 1fr',
            gutter: 0.5,
          }}
        >
          <UI.Div>
            <UI.Icon sc={{ color: 'secondary', mt: -0.25 }}>
              <Check />
            </UI.Icon>
          </UI.Div>
          <UI.Div>
            <UI.Strong>
              {t('tickets_form.charity_activated')}
            </UI.Strong>
            <UI.Div>
              {preselectedCharity.title}
            </UI.Div>
          </UI.Div>
            {preselectedCharity.logo_url && (
            <UI.Div>
              <img
                src={preselectedCharity.logo_url}
                alt={preselectedCharity.title}
                style={{ display: 'block', objectFit: 'contain', width: '100%', height: 40 }}
              />
            </UI.Div>
            )}
        </UI.GridContainer>
      )}
      <UI.FadeIn>
        <UI.FormGrid sc={{ gutter: 0.75 }}>
          <UI.Legend>
            {t('tickets_form.choose_your_tickets')}
          </UI.Legend>

          <TicketSelector />
        </UI.FormGrid>
      </UI.FadeIn>
    </>
  );
};

export default TicketsForm;
