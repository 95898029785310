import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateUpgradeInput } from '__generated__/graphql';
import { NestedTouchState } from '../../../common/useTouchState';
import { Product } from '../helpers';
import { calculateQuantities, calculateUpgradeAvailability } from '../useQuantities';
import { filterPrefix } from '../../../common/helpers';
import { useEvent } from '../EventProvider';
import CheckoutContext from '../CheckoutContext';
import ProductSelector from '../Personalisation/ProductSelector';
import UI from '../../../common/UI';
import UpgradeInfoForm from '../Personalisation/UpgradeInfoForm';

export interface StandAloneProductSelectorProps {
  products: Product[];
}

const StandAloneProductSelector = ({
  products,
}: StandAloneProductSelectorProps) => {
  const { t } = useTranslation();
  const { event } = useEvent();

  const {
    form,
    setStandAloneUpgrades,
    editStandAloneUpgrade,
    quantities,
    availability,
    validators: {
      extras: {
        errors,
      },
    },
    touch,
    touched,
  } = useContext(CheckoutContext);

  const upgradeQuantities = calculateQuantities({
    standAloneUpgrades: form.registrations.stand_alone_upgrades,
    products,
  });

  const upgradeAvailability = calculateUpgradeAvailability({
    quantities: upgradeQuantities,
    availability,
    products,
  });

  const handleProductsChange = useCallback(
    (upgrades: CreateUpgradeInput[]) => setStandAloneUpgrades(
      () => upgrades,
      event,
    ),
    [setStandAloneUpgrades, event],
  );

  const handleFieldChange = (index: number) => (upgrade: CreateUpgradeInput) => {
    editStandAloneUpgrade((oldValue) => ({ ...oldValue, fields: upgrade.fields }), index);
  };

  return (
    <UI.FormGrid>
      <UI.Legend>
        {t('extras_form.miscellaneous')}
      </UI.Legend>
      <ProductSelector
        event={event}
        products={products}
        upgrades={form.registrations.stand_alone_upgrades}
        onChange={handleProductsChange}
        quantities={upgradeQuantities}
        globalQuantities={quantities}
        availability={upgradeAvailability}
      />
      {form.registrations.stand_alone_upgrades.map((upgrade, index) => upgrade.fields.length > 0 && (
        <UpgradeInfoForm
          upgrade={upgrade}
          fields={event.enabled_participant_fields}
          product={products.filter((product) => (
            product.promotions_for_sale.filter(({ id }) => id === upgrade.purchase.promotion.id).length > 0
          ))[0]}
          onChange={handleFieldChange(index)}
          errors={filterPrefix(errors, `registrations.stand_alone_upgrades.${index}`)}
          onBlur={(key: string) => touch(`registrations.stand_alone_upgrades.${index}.${key}`)}
          touched={touched(`registrations.stand_alone_upgrades.${index}`) as NestedTouchState}
          id={`UpgradeInfoForm_${index}`}
          key={index}
        />
      ))}
    </UI.FormGrid>
  );
};

export default StandAloneProductSelector;
